.wrapper
  .pc-content
    .headerInner
      .col-sm-8
        padding-right 0
      .col-sm-6
        @media screen and (min-width: 768px) and (max-width: 991px)
          padding 0
      ul
        margin 7px 0 0 0
        list-style none
        padding-left 0
        li
          float left
          padding-bottom 15px
          position relative
          .active
            width 0
            height 0
            border-left 3px solid transparent
            border-bottom 5px solid color-black
            border-right 3px solid transparent
            position absolute
            bottom 0
            left 46%
          a
            margin 0 20px
            font-size 0.875em
            color color-black
            &:hover
              opacity 0.7
              text-decoration none
            @media screen and (min-width: 768px) and (max-width: 991px)
              font-size 0.75em
              margin 0 10px
            @media screen and (min-width: 992px) and (max-width: 1199px)
              margin 0 14px
            &:active
              color color-365ca9
          .careers
            margin-right 0
          .active
            color color-365ca9
      .header-reservation
        @media screen and (min-width: 768px) and (max-width: 991px)
          width 22%
        .content
          width 190px
          float right
          @media screen and (min-width: 768px) and (max-width: 991px)
            width 160px
        .fa-phone
          width 26px
          height 26px
          border-radius 50%
          background-color color-black
          color color-white
          margin-left -8px
          margin-right 5px
          &::before
            margin-left 4px
          @media screen and (min-width: 768px) and (max-width: 991px)
            width 23px
            height 23px
        .telephone
          color color-black
@media screen and (min-width: 768px) and (max-width: 991px)
  .wrapper
    .pc-content
      .headerInner
        ul
          margin-top 0