.home-content-wrap
    .first-view
        background url("../images/bg_firstview.jpg") no-repeat center
        background-size cover
        .text-banner-first
            margin-left 74%
            background-color rgba(253,251,248,0.9)
            background-image url("../images/img_firstview.png")
            background-repeat no-repeat
            background-position 21% 72%
            background-size 36.5% 28%
            width 22%
            @media only screen and (max-width: 767px)
                width 100px
            @media only screen and (max-width: 400px)
                margin-left 65%
            span
                font-size 2.25em
                font-family "RyuminPro-Light"
                display block
                line-height 1
                padding 40% 0 40% 33%
                color color-black
                @media only screen and (max-width: 991px)
                    padding 25% 0 25% 55%
                @media only screen and (max-width: 767px)
                    padding 25% 0 25% 50%
                    font-size 4.8vw
    .first-block
        margin-top 70px
        .title-block
            font-family "RyuminPro-Light"
            font-size 2em
            @media screen and (max-width: 375px)
                font-size 1.75em
            p
                margin-bottom 34px
        .content-block
            font-size 1.125em
            @media screen and (max-width: 767px)
                font-size 1em
            p
                margin-bottom 57px
                line-height 1.8em
        .images-block
            .image
                &:first-child
                    padding-left 0
                    padding-right 15px
                &:nth-child(2)
                    padding-right 10px
                    padding-left 5px
                &:nth-child(3)
                    padding-right 5px
                    padding-left 10px
                &:last-child
                    padding-right 0
                    padding-left 15px
                @media screen and (max-width: 991px) and (min-width: 768px)
                    margin-bottom 2%
                    &:nth-child(2n+1)
                        padding-right 10px
                        padding-left 0
                    &:nth-child(2n)
                        padding-right 0
                        padding-left 10px
                @media screen and (max-width: 767px)
                    margin-bottom 2%
                    &:first-child, &:nth-child(2), &:nth-child(3), &:last-child
                        padding-left 0
                        padding-right 0
                @media screen and (max-width: 991px) and (min-width: 768px)
                    &:nth-child(2n+1)
                        text-align right
                    &:nth-child(2n)
                        text-align left
    .second-block
        margin-top 54px
        padding-bottom 40px
        background-color color-efefef
        .title-block
            padding-top 35px
            font-family "RyuminPro-Light"
            font-size 2em
            p
                margin-bottom 28px
        .content-block
            display inline-block
            .blog-content
                display inline-block
                padding 0 0 40px
                max-height 220px
                &:nth-child(2n)
                    .blog-image, .blog-information
                        @media screen and (min-width: 768px)
                            left 20px
                @media screen and (max-width: 991px)
                    padding 0 2% 4%
                    max-height none
                @media screen and (max-width: 767px)
                    padding-top 3%
                .blog-image
                    width auto
                    display inline-block
                    padding 0
                    img
                        max-width 180px
                        max-height 180px
                .blog-information
                    padding 0
                    p
                        margin-left 10px
                        margin-bottom 12px
                        @media screen and (max-width: 991px)
                            margin-top 4%
                        @media screen and (max-width: 767px)
                            margin-bottom 1%
                        span
                            display block
                            margin-bottom 5px
                            a
                                color color-black
                        .blog-title
                            font-size 1.125em
                        .blog-excerpt
                            max-height 72px
                            overflow hidden
                            @media screen and (max-width: 767px)
                                max-height none
                    .small-button
                        padding 5px 10px
                        font-size 0.875em
        .big-button
            margin-top 8px
            font-size 1.25em
    .third-block
        .title-block
            padding-top 55px
            font-family "RyuminPro-Light"
            font-size 2em
            p
                margin-bottom 28px
        .content-block
            .blog-content
                display table
                margin-bottom 40px
                @media screen and (max-width: 991px)
                    display block
                    margin-bottom 16%
                .blog-image
                    padding 0 20px 0 0
                    display table-cell
                    float none
                    @media screen and (min-width: 992px)
                        &.col-md-push-6
                            padding-left 20px
                            padding-right 0
                    @media screen and (max-width: 991px)
                        padding 0
                        display block
                        margin-bottom 5%
                .blog-information
                    padding 0 0 0 20px
                    display table-cell
                    vertical-align middle
                    float none
                    @media screen and (max-width: 991px)
                        padding 0
                    @media screen and (min-width: 992px)
                        &.col-md-pull-6
                            padding-left 0
                            padding-right 20px
                    @media screen and (max-width: 991px)
                        display block
                    .title
                        font-size 1.25em
                        padding-bottom 18px
                        margin 0 10px 30px
                        border-bottom 1px solid color-black
                        @media screen and (max-width: 375px)
                            font-size 1em
                            padding-bottom 7%
                            margin 0 3.5% 7%
                        @media screen and (max-width: 991px) and (min-width: 768px)
                            margin 0 125px 30px
                        .sub-title
                            font-size 1.75em
                            margin-right 18px
                            @media screen and (max-width: 767px)
                                margin-right 2%
                    .big-button
                        font-size 1.25em
                        @media screen and (max-width: 375px)
                            font-size 1em
    .fourth-block
        margin-top 15px
        padding-bottom 32px
        background-color color-ebf3e2
        .title-block
            padding-top 30px
            font-family "RyuminPro-Light"
            font-size 2em
            p
                margin-bottom 33px
                .special-text
                    color color-617915
        .content-block
            .blog-content
                padding 0
                @media screen and (max-width: 1199px) and (min-width: 768px)
                    &:first-child
                        padding-right 5px
                    &:nth-child(2)
                        padding-left 5px
                .area
                    font-size 2em
                    font-family 'RyuminPro-Light'
                    border 1px solid color-black
                    border-radius 50%
                    padding 0 13px
                    width 70px
                    height 70px
                    display inline-block
                    @media screen and (max-width: 767px)
                        width 65px
                        height 65px
                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
                        padding 11px 13px
                    .bigger-text
                        font-size 1.25em
                .area-information
                    display inline-block
                    margin-top 30px
                    margin-bottom 28px
                    span
                        float left
                        text-align left
                        &.col-info-left
                            padding-right 10px
                .area-image
                    width auto
                    .big-img
                        padding 0
                        @media screen and (max-width: 991px)
                            text-align center
                    .two-small-imgs-group
                        padding 0
                        @media screen and (max-width: 991px)
                            display inline-block
                        .small-img-1, .small-img-2
                            padding 10px 0 0
                            float left
                            @media screen and (min-width: 1200px)
                                width auto
                            @media screen and (max-width: 1199px) and (min-width: 768px)
                                width 50%
                            @media screen and (max-width: 515px)
                                width 50%
                        .small-img-1
                            padding-right 5px
                        .small-img-2
                            padding-left 5px
                &:nth-child(2)
                    @media screen and (max-width: 767px)
                        margin-top 7%
                    .area-image
                        text-align right
                        @media screen and (max-width: 991px)
                            text-align center
                        .two-small-imgs-group
                            .small-img-1, .small-img-2
                                float right
                            .small-img-1
                                padding-left 5px
                                padding-right 0
                            .small-img-2
                                padding-left 0
                                padding-right 5px
    .fifth-block
        padding-top 45px
        padding-bottom 45px
        text-align center
        .title-block
            font-family "RyuminPro-Light"
            font-size 2em
            p
                margin-bottom 30px
        .content-block
            .google-map
                padding 0 20px 0 0
                @media screen and (max-width: 767px)
                    padding 0
                iframe
                    width 600px
                    height 470px
                    border 0
                    @media screen and (max-width: 767px)
                        height 300px
            .store-info
                padding-top 30px
                padding-left 10px
                @media screen and (max-width: 991px) and (min-width: 768px)
                    padding-top 0
                @media screen and (max-width: 767px)
                    padding-left 0
                .introduce
                    padding-top 20px
                    margin-bottom 0
                .telephone
                    padding-top 5px
                    a
                        font-family "Century"
                        font-size 1.5em
                        color color-black
                        &:hover
                            opacity 0.7
                        i
                            margin 0 5px
                            width 26px
                            height 26px
                            border-radius 50%
                            background-color color-black
                            color color-white
                .schedule
                    margin-top 20px
                    table
                        max-width 425px
                        margin 0 auto
                        border-collapse separate
                        border-spacing 5px
                        @media screen and (max-width: 767px)
                            margin 2% auto 0
                            max-width 385px
                        th
                            background-color color-efefef
                            color color-black
                            margin-bottom 10px
                            vertical-align top
                            text-align center
                            width 90px
                            font-size 1em
                            padding-top 8px
                            padding-bottom 8px
                        td
                            text-align left
                            font-size 1em
                            padding 8px 0 8px 0px
                        .open-hour
                            margin-top 8px
                            margin-bottom 0
    .sixth-block
        background url('./../images/block6_bg.jpg') no-repeat center
        background-size cover
        height auto
        .sixth-block-content
            max-width 680px
            height 365px
            display inline-block
            color color-black
            margin 58px
            background url('./../images/block6_text_bg.png') no-repeat
            background-size cover
            font-size 0.875em
            padding 25px 40px
            box-shadow 5px 0px 3px #fcfcf6
            @media screen and (max-width: 767px)
                height 420px
            @media screen and (max-width: 500px)
                height 400px
                margin 5%
                padding 25px 10px
            @media screen and (max-width: 375px)
                height auto
            hr
                background-color color-black
                margin 16px 0
            .sixth-block-title
                line-height 1
                margin 0
            .first-text
                font-size 1.43em
                margin-right 20px
            .second-text
                font-size 2em
            .sixth-block-description
                padding-left 15px
                margin 0
                line-height 1.5em
.seven-block
    background url("../images/block7_bg.png") no-repeat center
    background-size cover
    padding-top 34px
    padding-bottom 123px
    .box-content
        width 443px
        height 203px
        margin 0 auto
        text-align center
        color color-white
        border-radius 25px
        background-color rgba(95,12,12,0.7)
        box-shadow  1px 1px 1px color-9b4c47
        @media screen and (max-width: 550px)
            width 280px
            height 135px
            font-size 0.75em
        p
            padding-top 13px
            margin-bottom 0
            text-shadow 1px 1px 1px color-9b4c47
            &:first-child
                font-size 2em
                font-family 'RyuminPro-Light'
        .horizontal-line
            width  95%
            height 1px
            padding-top 0
            margin 7px auto 0
            background-color color-white
        .contact-info
            padding-top 13px
            padding-bottom 20px
            background url("../images/block7_phone.png") no-repeat 15% 45%
            @media screen and (max-width: 550px)
                margin-top 2%
                padding-top 0
                padding-bottom 5px
                background-size contain
                background-position 5% 45%
            p
                padding-top 0
                &:nth-child(1)
                    font-size 3em
                    font-family 'Century'
                    line-height 1em
                    margin-left 17%
                &:nth-child(2), &:nth-child(3)
                    font-size 1.125em
                &:nth-child(2)
                    margin-left 23%
                &:nth-child(3)
                    margin-left 25%
                .phone-number
                    color color-white