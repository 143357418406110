color-black = #000
color-white = #fff
color-efefef = #efefef
color-ebf3e2 = #ebf3e2
color-617915 = #617915
color-365ca9 = #365ca9
color-9b4c47 = #9b4c47
color-e83837 = #e83837
color-a71312 = #a71312
color-b92422 = #b92422

.big-title
    font-family 'RyuminPro-Light'
    font-size 2em
    line-height 1
    
.blogs-container
    max-width 980px
    display inline-block
    @media screen and (max-width: 992px)
        width 100%

.small-button, .big-button
    display inline-block
    text-align center
    text-decoration none
    outline none
    color color-black
    background-color color-efefef
    border-radius 55px
    &:visited
        color color-black
    &:hover
        text-decoration none
        opacity 0.7
.small-button
    border-bottom 1px solid color-black
    i
        margin-right 5px
        
.big-button
    border 1px solid color-black
    font-family 'RyuminPro-Light'
    background-color color-white
    padding 4px 20px
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        line-height 1
        padding 7px 20px 5px
    i
        padding-right 15px
        
a
    &:hover, &:focus
        outline 0
        text-decoration none
.error-page
    .site-main
        padding-left 15px
        padding-right 15px