.detail
    .news-content
        margin-top 5px
        padding-left 0
        padding-right 55px
        .date, .tags, .categories
            padding 0
            .col-xs-1, .col-xs-11
                padding 0
            .col-xs-1
                width 20px
        .tags, .categories
            margin-top 5px
            a
                color color-black
        .date
            .col-xs-6
                padding 0
        @media screen and (max-width: 767px)
            padding-right 0
        .col-sm-6
            padding-left 0
            &:first-child
                span
                    &:first-child
                        margin-right 45px
            &:nth-child(2)
                span
                    margin-left 120px
                    @media screen and (max-width: 1199px)
                        margin-left 3%
                    @media screen and (max-width: 767px)
                        margin-left 0
                a
                    margin-left 74px
                    @media screen and (max-width: 1199px) and (min-width: 768px)
                        margin-left 5%
        .title-detail
            padding-bottom 6px
            border-bottom 1px solid color-black
            font-size 1.5em
            margin-top 5px
            padding-left 0
        .content-detail
            margin-top 10px
            padding 0
            @media screen and (max-width: 767px)
                padding-left 0
                padding-right 0
        .fa-tag
            text-shadow -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000
            color #fff