.news-title
  text-align center
  p
    font-family 'RyuminPro-Light'
    font-size 1.75em
    margin-bottom 0
    padding 40px 0
    background-color color-efefef
    color color-black
    @media screen and (max-width: 767px)
      padding 5% 0
.list-news
  max-width 980px
  padding 40px 0 60px
  @media screen and (max-width: 767px)
    padding 40px 15px 60px
  p
    margin-bottom 0
  .box-item
    margin-top 40px
    color color-black
    &:first-child
      margin-top 0
    .img
      padding 0
      @media screen and (max-width: 550px)
        text-align center
        width 100%
        float none
    .content
      padding-right 41px
      @media screen and (max-width: 550px)
        padding 0
        width 100%
        float none
      .date
        font-size 1em
        @media screen and (max-width: 550px)
          margin-top 15px
      .title
        font-size 1.125em
        p
          line-height 2
          a
            color color-black
      .content
        font-size 1em
        padding-right 0
      .see-next
        text-align right
        margin-top 15px
        a
          color color-black
          font-size 0.875em
          font-family 'RyuminPro-Light'
          padding 2px 10px
          border-radius 20px
          border-bottom 1px solid color-black
          &:hover
            text-decoration none
            border-bottom 2px solid color-black
  .sidebar
    padding 0
    color color-black
    @media screen and (max-width: 767px)
      margin-top 5%
    .title
      font-family 'RyuminPro-Light'
      font-size 1.25em
      border-bottom 1px solid black
      text-align center
      @media screen and (max-width: 767px)
        text-align left
      p
        line-height 1.7
    .content
      margin-top 10px
      padding-left 10px
      @media screen and (max-width: 767px)
        padding-left 0
        .item-content
          margin-right 0
    .keyword, .category
      max-height 300px
      min-height 100px
      margin-bottom 5%
      overflow auto
      a
        color color-black
        font-size 0.875em
        font-family 'Meiryo'
    .keyword
      i
        text-shadow -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000
        color color-white
        @media screen and (max-width: 767px)
          margin-left 1px
      .tags-links
        a
          margin-right 20px
          display inline-block
    .what-new
      .title
        p
          padding-right 20px
          i
            text-shadow -1px -1px 0px #000, 1px -1px 0px #000, -1px 1px 0px #000, 1px 1px 0px #000
            color color-white
      .content
        .item-content
          margin-left 0
          margin-bottom 10px
          @media screen and (min-width: 768px) and (max-width: 991px)
            margin-right 0
        .content-right
          padding-left 8px
          p
            &:first-child
              font-size 0.875em
              margin-top 5px
            &:last-child
              line-height 1.7
            a
              color color-black
            i
              border-radius 20px
              padding 0 6px
              border-bottom 1px solid black
        .content-left
          padding 0
    .plugin-facebook
      margin-top 40px
      @media screen and (max-width: 767px)
        text-align center
.pagination
    margin 50px -15px 0
    @media screen and (max-width: 767px)
        margin 50px 0 0
        width 100%
        text-align center
    .custom-pagination
        span, a
            font-weight bold
            display inline-block
            padding 2px 10px
            background-color color-white
            color color-black
            width 50px
            height 50px
            border-radius 50%
            border 1px solid color-black
            text-align center
            font-size 1.1em
            line-height 2.4em
            margin-right 15px
            @media screen and (max-width: 767px)
                width 40px
                height 40px
                margin-right 5px
        a
            &:hover
                background-color color-b92422
                color color-white
            &:last-child
                margin-right 0
        span.current
            background-color color-b92422
            color color-white