footer
  background-color color-black
  color color-white
  .footer-content
    .box-img-menu
      padding 0
    max-width 980px
    margin 28px auto 0
    @media screen and (max-width: 767px)
      padding 0 15px
    @media screen and (min-width: 768px) and (max-width: 1199px)
      padding-left 0
    @media screen and (max-width: 375px)
      font-size 14px
    @media screen and (max-width: 320px)
      font-size 12px
  .footer-menu
    margin-top 35px
    padding 0
    @media screen and (max-width: 1199px)
      margin-left 0
    a
      color color-white
    ul
      margin-left 0
      padding-left 10px
      border-left 1px dotted color-white
      @media screen and (max-width: 767px)
        border-left none
        width 400px
        margin 0 auto
      @media screen and (max-width: 375px)
        width 340px
      @media screen and (max-width: 320px)
        width 300px
      li
        list-style none
        position relative
        padding-left 10px
        line-height 1.45
        @media screen and (max-width: 767px)
          float left
          width 50%
        a
          font-size 0.75em
        .ic_foot
          top 10px
          width 0
          height 0
          border-top 3px solid transparent
          border-left 5px solid color-white
          border-bottom 3px solid transparent
          position absolute
          left 0
  .copyright
    font-family 'Meiryo'
    font-size 0.75em
    p
      padding 13px 0
      background-color color-white
      color color-black
      margin-top 30px
      margin-bottom 0
      @media screen and (max-width: 767px)
        padding 15px
  .footer-contact
    padding 0
    text-align center
    table
      max-width 425px
      margin 0 auto
      @media screen and (max-width: 767px)
        margin 20px auto 0
        max-width 365px
      border-collapse separate
      border-spacing 5px
      th
        background-color color-white
        color color-black
        margin-bottom 10px
        vertical-align top
        text-align center
        width 70px
        font-size 0.875em
      td
        text-align left
        font-size 0.875em
      .open-hour
        margin-top 8px
    img
      margin-bottom 10px
    p
      margin-bottom 0
      font-size 1em
    .reservation
      font-size 0.875em
      span
        font-size 1.715em
        font-family 'Century'
        @media screen and (max-width: 375px)
          font-size 1.6em
      .fa-phone
        margin 0 5px
        width 26px
        height 26px
        border-radius 50%
        background-color color-white
        color color-black
        &::before
          margin-left 3px
          @media screen and (max-width: 320px)
            margin-left 0
        @media screen and (max-width: 375px)
          width 23px
          height 23px
        @media screen and (max-width: 320px)
          width 20px
          height 20px
      .telephone
        color color-white
    .col-xs-3
      padding 0
  .footer-img
    margin-top 30px
    padding 0
    @media screen and (min-width: 768px) and (max-width: 1199px)
      margin-left 0